import React from "react"
import styled from "styled-components"
import OrganonIcon from '../../assets/organon-icon.svg';
import BlueGradientLine from '../../assets/blue-gradient-line.png';
import Checkbox from '../../assets/checkbox.png';

const HcpOverlay = ({ closeOverlay }) => {
    return (
    <OverlayWrapper>
        <OverlayContainer>
            <IconContainer>
                <OrganonIcon/>
            </IconContainer>
            <OverlayHeader>
                WELCOME
            </OverlayHeader>
            <OverlaySubheader>
                TO THE <br/>
                ORGANON ACCESS PROGRAM
            </OverlaySubheader>
            <BlueGradientLineContainer/>
            <OverlayQuestion>
                Are you a US health care professional?
            </OverlayQuestion>
            <OverlayQuestionChoiceContainer>
                <OverlayQuestionChoice>
                    <button 
                        onClick={() => closeOverlay('hcp')}>
                        <img src={Checkbox} alt=""/>YES
                    </button>
                </OverlayQuestionChoice>
                <OverlayQuestionChoice>
                    <button 
                        onClick={() => closeOverlay('hcc')}>
                        <img src={Checkbox} alt=""/>NO
                    </button>
                </OverlayQuestionChoice>
            </OverlayQuestionChoiceContainer>
        </OverlayContainer>
    </OverlayWrapper>
)}

const OverlayWrapper = styled.div`
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    align-content: center;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
`
const OverlayContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--white);
    border-radius: 100%;
    border: 5px solid var(--pink);
    position: absolute;
    width: 492px;
    height: 492px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    @media (max-width: 415px) {
        width: 300px;
        height: 300px;
    }
`

const OverlayHeader = styled.div`
    text-align: center;
    color: var(--dark-blue);
    font-size: 56px;
    font-weight: bold;

    @media (max-width: 415px) {
        font-size: 35px;
    }
`

const OverlaySubheader = styled.div`
    text-align: center;
    font-size: 22px;
    color: var(--dark-blue);
    font-weight: bold;
    margin-top: 0.75rem;

    @media (max-width: 415px) {
        font-size: 14px;
        line-height: 16px;
    }
`

const IconContainer = styled.div`
    svg {
        width: 100px;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media (max-width: 415px) {
            width: 60px;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
        }
    }
`

const BlueGradientLineContainer = styled.div`
    margin-top: 2.2rem;
    margin-bottom: 2rem;
    background-image: url(${BlueGradientLine});
    background-size: contain;
    background-repeat: no-repeat;
    width: 95%;
    height: 5px;

    @media (max-width: 415px) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
`

const OverlayQuestion = styled.div`
    color: var(--pink);
    font-weight: bold;

    @media (max-width: 415px) {
        text-align: center;
        font-size: 12px;
    }
`

const OverlayQuestionChoiceContainer = styled.div`
    display:flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 60%;
    height: 30px;
    margin-top: 2rem;

    @media (max-width: 415px) {
        margin-top: 1rem;
        font-size: 14px;
    }
`

const OverlayQuestionChoice = styled.div`
    font-weight: bold;
    position: relative;
    
    button {
        border: none;
        background-color: var(--white);
        padding-left: 35px;
        color: var(--pink);

        img {
            position: absolute;
            width: 24px;
            height: 24px;
            margin-left: -30px;

            @media (max-width: 415px) {
                margin-top: -2px;
            }
        }
    }
`


export default HcpOverlay