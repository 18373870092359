import React from "react"
import styled from "styled-components"

const PageDivider = () => (
    <PageDividerContainer>
    
    </PageDividerContainer>
)

const PageDividerContainer = styled.div`
    background: linear-gradient(to top, #e6e7e8 0, #fff 15px, #fff 100%);
    width: calc(100% + 50px);
    transform: translateX(-25px);
    height: 15px;

    @media (max-width: 415px) {
        width: calc(100% + 2.175rem + 8px);
    }
`

export default PageDivider