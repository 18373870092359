import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { GlobalStyle } from "../globalStyle"
import { OneTrustOverrides } from '../globalStyle';
import { MainWrapper } from "./layoutComponents"
import HcpOverlay from "../../components/HcpOverlay"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const Layout = ({ children }) => {

  const [showOverlay, setShowOverlay] = useState(() => {
    if (isBrowser && !window.sessionStorage.getItem('showOverlay')) return true;
    else return false;
  });

  const closeOverlay = (userType) => {
    if (isBrowser) window.sessionStorage.setItem('showOverlay', 'false');
    
    if (isBrowser && !window.location.pathname.includes(userType)) return window.location.href = `/${userType}`;
    
    setShowOverlay(false);
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <GlobalStyle />
          <OneTrustOverrides />
          <noscript dangerouslySetInnerHTML={{__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5LVTRH3" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,}}/>
          <MainWrapper>
              <main>{children}</main>
          </MainWrapper>

          {showOverlay && 
            <HcpOverlay closeOverlay={closeOverlay} />
          }
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
