import styled from "styled-components"

export const ComponentWrapper = styled.div`
  margin: 0 auto;
  min-height: 100%;
  border-left: 15px solid #E20C78;
  border-right: 15px solid #E20C78;
  padding: none;
  padding: 0px 6px;
  width: 985px;

  @media (max-width: 780px) {
    border-left: 15px solid #E20C78;
    border-right: 15px solid #E20C78;
    max-width: 100%;
  }

  @media (max-width: 415px) {
      border: none;
      padding: 0;
      max-width: 100%;
  }
`